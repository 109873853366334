import { __ } from '@wordpress/i18n';
import { useBlockProps } from '@wordpress/block-editor';
import {RichText} from '@wordpress/block-editor';

/**
 * The save function defines the way in which the different attributes should
 * be combined into the final markup, which is then serialized by the block
 * editor into `post_content`.
 *
 * @see https://developer.wordpress.org/block-editor/developers/block-api/block-edit-save/#save
 *
 * @return {WPElement} Element to render.
 */
export default function save({className, attributes}) {
	return (
		<div className="peugeauto-featured-product">
		<div className="peugeauto-featured-product__leftside">
			<div className="peugeauto-featured-product__yellowline"></div>
			<div className="peugeauto-featured-product__imagewrap">
				<div className="peugeauto-featured-product__imagewrapinner">
					<div className="peugeauto-featured-product__imagedecorator"></div>
					<img className="peugeauto-featured-product__image" src={attributes.image} />
				</div>
			</div>
		</div>
		<div className="peugeauto-featured-product__rightside">
			<div className="peugeauto-featured-product__title-outerwrapper">
				<div className="peugeauto-featured-product__title-wrapper">
					<h3 className="peugeauto-featured-product__title">{attributes.title}</h3>
				</div>
			</div>
			<div className="peugeauto-featured-product__description">
				<RichText.Content value={attributes.description} />
			</div>
			<div className="peugeauto-featured-product__linkwrapper">
				<a href={attributes.link} className="peugeauto-featured-product__link">Bestellen</a>
			</div>
		</div>
	</div>
	);
}
