import { __ } from '@wordpress/i18n';
import './editor.scss';
import {useBlockProps, InspectorControls, MediaUpload} from '@wordpress/block-editor';
import {PanelBody, Button, TextControl, ComboboxControl} from '@wordpress/components';
import {RichText} from '@wordpress/block-editor';
import {useEffect, useState} from 'react'

export default function Edit({attributes, setAttributes}) {
	const selectImage = (media) => {
		setAttributes({
			image: media.sizes.full.url
		})
	}
	
	const renderMediaUpload = ({open}) => {
		return (
			<Button
				variant="primary"
				onClick={open}
			>Kies afbeelding</Button>
		)
	}

	const [products, setProducts] = useState([])

	useEffect(() => {
		const getProducts = async () => {
			const response = await fetch(ajaxurl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
				},
				body: 'action=get_woocommerce_products'
			})
			const result = await response.json()
			const collectedProducts = result.map(p => {
				return {"label": p.name, "value": p.slug}
			})
			setProducts(collectedProducts)
		}
		getProducts()
	}, [])

	console.log(attributes.link)

	return(
		<>
		<div {...useBlockProps()}>
			<InspectorControls key="setting">
				<PanelBody title="Afbeelding">
					<MediaUpload 
						onSelect={selectImage}
						render={renderMediaUpload}
					/>
				</PanelBody>
			</InspectorControls>
			<div className="peugeauto-featured-product">
				<div className="peugeauto-featured-product__leftside">
					<div className="peugeauto-featured-product__yellowline"></div>
					<div className="peugeauto-featured-product__imagewrap">
						<img className="peugeauto-featured-product__image" src={attributes.image} />
					</div>
				</div>
				<div className="peugeauto-featured-product__rightside">
					<div className="peugeauto-featured-product__title-wrapper">
						<TextControl
							label="Titel"
							value={attributes.title}
							onChange={ v => setAttributes({title: v})}
						/>
					</div>
					<div className="peugeauto-featured-product__description-wrapper">
						<RichText
							tagName="p"
							value={attributes.description}
							onChange={ content => setAttributes({description: content})}
							placeholder="Omschrijving"
						/>
					</div>
					<div className="peugeauto-featured-product__buttonwrapper">
						<ComboboxControl
							label="Zoek een product"
							value={products.length > 0 ? products[0].value: ''}
							onChange={val => setAttributes({link: val})}
							options={products}
						/>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}
