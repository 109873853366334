import {registerBlockType} from '@wordpress/blocks';
import {ColorPicker, PanelBody, Button} from '@wordpress/components';
import {withState} from '@wordpress/compose';

const MyColorPicker = withState(
{
	color: '#f23453'
})
(
	({color, setState}) => {
		return (
			<ColorPicker
				color={color}
				onChangeComplete={(v) => setState({color: v.hex})}
				enableAlpha
			/>
		)
	}
)
import './style.scss';

import Edit from './edit';
import save from './save';

/**
 * Every block starts by registering a new block type definition.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-registration/
 */
registerBlockType( 'peugeauto/featured-product', {
	title: 'Uitgelicht product',
	description: 'Blok waarmee een product extra onder de aandacht kan worden gebracht op andere pagina\'s',
	icon: 'format-image',
	category: 'layout',
	attributes: {
		image: {
			type: 'string',
			source: 'attribute',
			selector: 'img.peugeauto-featured-product__image',
			attribute: 'src',
			default: '',
		},
		title: {
			type: 'string',
			source: 'text',
			selector: 'h3.peugeauto-featured-product__title',
			default: 'Titel'
		},
		description: {
			type: 'string',
			source: 'html',
			selector: 'div.peugeauto-featured-product__description',
			default: 'Omschrijving'
		},
		link: {
			type: 'string',
			source: 'attribute',
			selector: 'a.peugeauto-featured-product__link',
			attribute: 'href'
		}
	},
	/**
	 * @see ./edit.js
	 */
	edit: Edit,

	/**
	 * @see ./save.js
	 */
	save,
} );
